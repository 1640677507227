/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      createdAt
      id
      inUse
      password
      platform
      updatedAt
      username
    }
  }
`;
export const getPlatform = /* GraphQL */ `
  query GetPlatform($id: ID!) {
    getPlatform(id: $id) {
      availableAccounts
      createdAt
      description
      id
      peopleInQueue
      providerName
      updatedAt
      expectedAvailable
      position {
        platform
        position
        user
        createdAt
        updatedAt
        pendingSince
      }
    }
  }
`;
export const getSubscription = /* GraphQL */ `
  query GetSubscription($id: ID!) {
    getSubscription(id: $id) {
      account {
        createdAt
        id
        inUse
        password
        platform
        updatedAt
        username
      }
      accountId
      active
      activeUntil
      createdAt
      extensionGranted
      extensionRequested
      id
      onCreateAccount {
        createdAt
        id
        inUse
        password
        platform
        updatedAt
        username
      }
      onDeleteAccount {
        createdAt
        id
        inUse
        password
        platform
        updatedAt
        username
      }
      onUpdateAccount {
        createdAt
        id
        inUse
        password
        platform
        updatedAt
        username
      }
      owner
      platform {
        availableAccounts
        createdAt
        description
        id
        peopleInQueue
        providerName
        updatedAt
        expectedAvailable
      }
      platformId
      providerName
      updatedAt
    }
  }
`;
export const getQueue = /* GraphQL */ `
  query GetQueue($platform: String, $position: Int) {
    getQueue(platform: $platform, position: $position) {
      platform
      position
      user
      createdAt
      updatedAt
      pendingSince
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        inUse
        password
        platform
        updatedAt
        username
      }
      nextToken
    }
  }
`;
export const listPlatforms = /* GraphQL */ `
  query ListPlatforms(
    $filter: ModelPlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlatforms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        availableAccounts
        createdAt
        description
        id
        peopleInQueue
        providerName
        updatedAt
        expectedAvailable
        position {
            position
        }
      }
      nextToken
    }
  }
`;
export const listSubscriptions = /* GraphQL */ `
  query ListSubscriptions(
    $filter: ModelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        active
        activeUntil
        createdAt
        extensionGranted
        extensionRequested
        id
        owner
        platformId
        providerName
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        platform
        position
        user
        createdAt
        updatedAt
        pendingSince
      }
      extToken
    }
  }
`;
