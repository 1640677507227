import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ExtensionVerification() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        var editorExtensionId = "pmhjkffhpgjamdkdccmjoejeaniepgcd";

        try {
        // @ts-ignore
        chrome.runtime.sendMessage(editorExtensionId, {"action": "ping", "url": "", "cookies": "{}"},
            function(response) {
                // console.log(response);
                if(response !== "pong")
                {
                    handleClickOpen();
                }
            });
        }  catch (e) {
            handleClickOpen();
        }

    }, []);

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Chrome extension"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please install this Google Chrome <a href="https://chrome.google.com/webstore/detail/academy-helper/pmhjkffhpgjamdkdccmjoejeaniepgcd?hl=en-GB&authuser=0" target="_blank" rel="noopener noreferrer">extension</a> in order to login to external course providers.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
    );
}