import * as React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter, RouteComponentProps  } from 'react-router-dom'
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import { Auth } from 'aws-amplify';
import {useEffect, useState} from "react";
import { Button, createTheme, ThemeProvider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
        },
        menu: {
            padding: 20,
        },
        user: {
            height: 30,
            width: 30
        },
        name: {
            lineHeight: 4
        }
    }),
);

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#fff'
        },
        grey: {
            900: '#000'
        }
    }
})

const Header: React.FunctionComponent<RouteComponentProps> = (props:RouteComponentProps<{}>) => {
    const classes = useStyles();
    const [, setUserInfo] = useState({username:''});

    useEffect(() => {
        (async function loadUserInfo() {
           const info = await Auth.currentUserInfo();
           setUserInfo(info);
           return true as const;
        })();

    }, []);

    return (
        <ThemeProvider theme={theme}>
        <AppBar position="static" color="default" variant={"outlined"}>
            <Toolbar id="toolbar">
                <Grid container spacing={0}>
                    <Grid item md={3} sm={12} style={{display: 'flex', alignItems:'center', userSelect: 'none'}}>
                        <img src="/images/logo.png" alt="Pentalog" style={{maxWidth: 200}}/>
                    </Grid>
                    <Grid item md={5} sm={12}>
                        <Tabs value={props.location.pathname} aria-label="simple tabs example" textColor={"primary"} indicatorColor={"primary"} style={{display:"none"}}>
                            <Tab label="Dashboard" value="/dashboard" className={classes.menu} onClick={() => props.history.push('/dashboard')}  />
                        </Tabs>
                    </Grid>
                    <Grid item md={4} sm={12} style={{display: 'flex', alignItems:'center', justifyContent: 'flex-end'}}>
                        <Button href="https://pentalog.sharepoint.com/:b:/s/Project-PPEN08/EcaS1OboDcRLmp3cJMjKU18Bq9bSADXcjzM07yDnbdMZOw?e=FKdybp&isSPOFile=1" target="_blank">User guide</Button>
                        <Button style={{marginLeft: 20}} href="https://dt.pentalog.com/servicedesk/customer/portal/2" target="_blank">Request a Course</Button>
                    </Grid>
                    {/*<Grid item xs={2}>*/}
                    {/*    {loggedIn && (*/}
                    {/*        <div className={classes.name}>*/}
                    {/*            <IconButton*/}
                    {/*                aria-label="account of current user"*/}
                    {/*                aria-controls="menu-appbar"*/}
                    {/*                color="inherit"*/}
                    {/*            >*/}
                    {/*                <AccountCircle  className={classes.user} />*/}
                    {/*            </IconButton>*/}
                    {/*            {userInfo.username}*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</Grid>*/}
                </Grid>
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    );
}

export default withRouter(Header);